<template>
  <div>
    <!-- SECTION modal-modify-confirm-segments -->
    <b-modal
      id="modal-modify-confirm-segments"
      title="Xác nhận chỗ"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
      @show="showHandle"
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-lg-25  border-0"
          variant="outline-info"
          :disabled="isEmpty(indexSelectedFlightToCancel)"
          pill
          @click="submitConfirmSegments"
        >
          <span
            class="align-middle"
          >
            Xác nhận chỗ
          </span>
        </b-button>
      </template>

      <div class="text-heading-5">
        Chọn hành trình xác nhận chỗ:
      </div>

      <b-card
        v-if="!isEmpty(getSegmentToConfirm(getBookingData.itineraries))"
        class="mt-1"
      >
        <b-form-group>
          <template #label>
            <!-- v-if="!isEmpty(getBookingData.itineraries)" -->
            <b-form-checkbox
              v-model="allSelected"
              aria-describedby="flights"
              aria-controls="flights"
              class="custom-checkbox-label mb-1"
              @change="toggleAll"
            >
              <div class="text-body-2">
                {{ allSelected ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
              </div>
            </b-form-checkbox>
          </template>
          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="checkbox-group-cancel-flight"
              v-model="indexSelectedFlightToCancel"
              :aria-describedby="ariaDescribedby"
              stacked
              name="flights"
            >
              <div
                v-for="(segment, indexSegment) in getSegmentToConfirm(getBookingData.itineraries)"
                :key="indexSegment"
                class="d-flex"
              >
                <b-form-checkbox
                  :value="segment.segmentId"
                  class="custom-checkbox-label mb-50"
                >
                  <span class="text-dark font-weight-bolder">{{ convertShortTrip(segment) }}</span>
                </b-form-checkbox>
              </div>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
      </b-card>

      <b-card
        v-else
        class="pb-0 mt-2"
        no-body
      >
        <b-alert
          variant="warning"
          class="py-50 mb-0 text-center font-weight-bolder font-medium-2 border-warning"
          show
        >
          Không có chặng bay có thể xác nhận chỗ!
        </b-alert>
      </b-card>
    </b-modal>
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BModal, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BButton, BAlert, BCard,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { ref, computed, watch } from '@vue/composition-api'
// import { useToast } from 'vue-toastification/composition'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'

// eslint-disable-next-line no-unused-vars
import { formatCurrency, convertISODateTime, convertShortTrip } from '@/@core/utils/filter'
// import { getUserData } from '@/api/auth/utils'
import IAmConfirm from '@/components/IAmConfirm.vue'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    IAmConfirm,
    // eslint-disable-next-line vue/no-unused-components
    BAlert,
    BCard,
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const indexSelectedFlightToCancel = ref([])
    const indexSelectedFlightToCancelComputed = computed(() => indexSelectedFlightToCancel.value.sort())

    const {
      getBookingData,
      confirmSegments,
    } = useReservationHandle()

    function getSegmentToConfirm(trips) {
      return trips
        .flat()
        .filter(seg => seg.status === 'KL')
    }

    // ANCHOR SELECT ALL
    const allSelected = ref(false)

    function toggleAll(checked) {
      indexSelectedFlightToCancel.value = checked
        ? getSegmentToConfirm(getBookingData.value.itineraries).map(seg => seg.segmentId).slice()
        : []
    }

    watch(indexSelectedFlightToCancel, newValue => {
      if (newValue.length === 0) {
        allSelected.value = false
      } else if (newValue.length === getBookingData.value.itineraries.flat().map(item => item.segmentId).slice().length) {
        allSelected.value = true
      } else {
        allSelected.value = false
      }
    })

    function submitConfirmSegments() {
      this.$bvModal
        .msgBoxConfirm('Bạn có chắc chắn muốn xác nhận chỗ trên những chặng bay đã chọn?', {
          title: this.$t('reservation.confirmSegments.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            confirmSegments({
              segmentIds: indexSelectedFlightToCancelComputed.value,
              pnrNumber: props.bookingData.bookingCode,
              source: props.bookingData.source,
              // agencyCode: props.bookingData.agency,
              // paxCode: props.bookingData.paxContact?.code ?? props.bookingData?.paxContact,
            })
              .then(() => {
                this.$bvModal.hide('modal-modify-confirm-segments')
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    function showHandle() {
      allSelected.value = false
      indexSelectedFlightToCancel.value = []
    }

    return {
      allSelected,
      getBookingData,
      indexSelectedFlightToCancel,
      indexSelectedFlightToCancelComputed,

      showHandle,
      submitConfirmSegments,
      getSegmentToConfirm,

      convertShortTrip,
      toggleAll,
      isEmpty,
      isArray,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .custom-checkbox-label::v-deep {
    .custom-control-label {
      margin-top: 2px !important;
    }
  }
  </style>
