var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-modify-confirm-segments",
      "title": "Xác nhận chỗ",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.showHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mt-lg-25  border-0",
          attrs: {
            "variant": "outline-info",
            "disabled": _vm.isEmpty(_vm.indexSelectedFlightToCancel),
            "pill": ""
          },
          on: {
            "click": _vm.submitConfirmSegments
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" Xác nhận chỗ ")])])];
      }
    }])
  }, [_c('div', {
    staticClass: "text-heading-5"
  }, [_vm._v(" Chọn hành trình xác nhận chỗ: ")]), !_vm.isEmpty(_vm.getSegmentToConfirm(_vm.getBookingData.itineraries)) ? _c('b-card', {
    staticClass: "mt-1"
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          staticClass: "custom-checkbox-label mb-1",
          attrs: {
            "aria-describedby": "flights",
            "aria-controls": "flights"
          },
          on: {
            "change": _vm.toggleAll
          },
          model: {
            value: _vm.allSelected,
            callback: function callback($$v) {
              _vm.allSelected = $$v;
            },
            expression: "allSelected"
          }
        }, [_c('div', {
          staticClass: "text-body-2"
        }, [_vm._v(" " + _vm._s(_vm.allSelected ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref2) {
        var ariaDescribedby = _ref2.ariaDescribedby;
        return [_c('b-form-checkbox-group', {
          attrs: {
            "id": "checkbox-group-cancel-flight",
            "aria-describedby": ariaDescribedby,
            "stacked": "",
            "name": "flights"
          },
          model: {
            value: _vm.indexSelectedFlightToCancel,
            callback: function callback($$v) {
              _vm.indexSelectedFlightToCancel = $$v;
            },
            expression: "indexSelectedFlightToCancel"
          }
        }, _vm._l(_vm.getSegmentToConfirm(_vm.getBookingData.itineraries), function (segment, indexSegment) {
          return _c('div', {
            key: indexSegment,
            staticClass: "d-flex"
          }, [_c('b-form-checkbox', {
            staticClass: "custom-checkbox-label mb-50",
            attrs: {
              "value": segment.segmentId
            }
          }, [_c('span', {
            staticClass: "text-dark font-weight-bolder"
          }, [_vm._v(_vm._s(_vm.convertShortTrip(segment)))])])], 1);
        }), 0)];
      }
    }], null, false, 2068425424)
  })], 1) : _c('b-card', {
    staticClass: "pb-0 mt-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-alert', {
    staticClass: "py-50 mb-0 text-center font-weight-bolder font-medium-2 border-warning",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_vm._v(" Không có chặng bay có thể xác nhận chỗ! ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }